import React, { Component } from "react";
import Map from "./Map";

class App extends Component {
  render() {
    return <Map />;
  }
}

export default App;
